<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div v-if="this.$store.getters.discriminator==='administrator'" class="col-2 ml-0 p-0">
        <multiselect v-model="oficeFilter" @input="filterData"
         :options="offices"
         :multiple="true"
         :close-on-select="false"
         :clear-on-select="false"
         :preserve-search="true"
          placeholder="Select Offices"
          label="office_name" 
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.office_name}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div v-else class="col-2 ml-0 p-0">
        <multiselect v-model="userFilter" @input="filterData" 
          :options="users" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          placeholder="Select User" 
          label="name" 
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.name}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div v-if="clients.length>0" class="col-2 ml-2 p-0">
        <multiselect v-model="clientFilter" @input="filterData" 
          :options="clients" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          placeholder="Select Client" 
          label="name" 
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.name}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-2 p-0">
        <multiselect v-model="categoryFilter" @input="filterData" 
        :options="[{value:'Pay Roll'},{value:'Licenses'},{value:'Sales Tax'},{value:'Corp Tax'}]" 
        :multiple="true" 
        :close-on-select="false" 
        :clear-on-select="false" 
        :preserve-search="true" 
        placeholder="Select Categories" 
        label="value" 
        track-by="value" 
        :select-label="''"
        :deselect-label="''"
        @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.value}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-2 p-0">
        <multiselect v-model="statusFilter" @input="filterData" 
        :options="[{value:'Pending'},{value:'Done'}]" 
        :multiple="true" 
        :close-on-select="false" 
        :clear-on-select="false" 
        :preserve-search="true" 
        placeholder="Select Status" 
        label="value" 
        track-by="value" 
        :select-label="''"
        :deselect-label="''"
        @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.value}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-2 p-0">
        <date-picker v-model="dateFilter" type="date" @change="filterData" range format="MM/DD/YYYY" placeholder="Select Range Date"></date-picker>
      </div>
    </div>
    <el-table 
    :data="this.tableData.filter(data => !search || data.corporate.name.toLowerCase().includes(search.toLowerCase()) 
    || data.category.toLowerCase().includes(search.toLowerCase()) 
    || data.users.name.toLowerCase().includes(search.toLowerCase())
    || data.status.toLowerCase().includes(search.toLowerCase()))"
      :default-sort="{ prop: 'corporate.name', order: 'ascending' }"
    class="w-100">
      <el-table-column
        label="Subject"
        fixed
        sortable
        width="250">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="bottom">
            <p>Subject: {{ scope.row.title }}</p>
            <p>Details: {{ scope.row.detail }}</p>
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.title }}</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="300" sortable prop="corporate.name" label="Client" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column width="250" sortable prop="users.name" label="Assigned To"></el-table-column>
      <el-table-column width="100" sortable prop="priority" label="Priority"></el-table-column>
      <el-table-column width="100" sortable prop="status" label="Status">
        <template slot-scope="scope">
          <i v-if="scope.row.status==='Done'" class="fal fa-check-circle text-success"></i>
          <i v-else class="fal fa-ban text-danger"></i>
          {{scope.row.status}}
        </template>
      </el-table-column>
      <el-table-column width="120" sortable prop="duedate" label="Due Date">
        <template slot-scope="scope">{{ scope.row.duedate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column width="120" sortable prop="completionDate" label="Completed">
        <template slot-scope="scope">{{ scope.row.completionDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="Operations" width="200">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" placeholder="Search..." class="p-0" :key="scope.row" />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row, scope.$index)"></el-button>
            <el-button size="mini" icon="el-icon-delete" @click="remove(scope.row, scope.$index)"></el-button>
            <el-button size="mini" v-if="scope.row.status==='Pending'" icon="el-icon-success" @click="completeTask(scope.row, scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="fixed-bottom new-register" type="success" @click="add">Add New Task</el-button>

    <el-button class="fixed-bottom new-register print-register" type="primary" @click="print">
      <i class="fal fa-print"></i>
      Print
    </el-button>


    <el-drawer title="New Task" :visible.sync="component.drawer" direction="rtl" size="500px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-note
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect';
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import corporate from "@/services/api/corporate";
import note from "@/services/api/note";
import ComponentNote from "@/components/admin/Todo";
import user from "@/services/api/user";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    Multiselect,
    ComponentNote,
    DatePicker 
  },
  data() {    
    return {  
      data:[],    
      tableData: [],
      oficeFilter:[],
      lengthOfficeFilter: null,
      userFilter:[],
      clientFilter:[],
      categoryFilter:[],
      statusFilter:[],
      dateFilter:[null,null],
      search:"",
      offices: [],
      clients: [],
      users: [],
      component: {
        data: null,
        drawer: false,
        render: 0,        
        index: null
      }
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        note.getTasks().then(response => {
            this.data = Object.assign([], response);
            this.tableData = response;
          });
        office.get().then(response => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            note.getTasksOffice(idOffice).then(response => {
              this.data = Object.assign([], response);
              this.tableData = response;
            });
            user.getUsersByOffice(idOffice).then(response => {
              this.users = response;
            });
        });
        break;
    }
  },
  methods: {
    print(){
      let offices = JSON.stringify(this.oficeFilter.map(office=>{
        return {id: office.id, name: office.office_name};
      }));

      let users = JSON.stringify(this.userFilter.map(user=>{
        return {id: user.id, name: user.name};
      }));

      let clients = JSON.stringify(this.clientFilter.map(client=>{
        return {id: client.id, name: client.name};
      }));

      let categories = JSON.stringify(this.categoryFilter.map(category=>{
        return category.value;
      }));

      let status = JSON.stringify(this.statusFilter.map(status=>{
        return status.value;
      }));

      let date = JSON.stringify(this.dateFilter)

      let routeData = this.$router.resolve({name: 'TasksPrint', query: {offices: offices,
                                                                        users: users,
                                                                        clients: clients,
                                                                        categories: categories,
                                                                        status: status,
                                                                        date: date}});
      window.open(routeData.href, '_blank');
    },
    getClients(idOffices){
      this.clients=[];
      idOffices.forEach(id=>{
        corporate.getClientsByOffice(id).then(response => {
          this.clients=this.clients.concat(response);
        });
      });
    },
    filterData(){
      let idOffices = this.oficeFilter.map(office=>{
        return office.id;
      });

      let idUsers = this.userFilter.map(user=>{
        return user.id;
      });

      let clients = this.clientFilter.map(client=>{
        return client.id;
      });

      let categories = this.categoryFilter.map(category=>{
        return category.value;
      });

      let status = this.statusFilter.map(status=>{
        return status.value;
      });
      

      this.tableData = Object.assign([], this.data);

      if(idOffices.length>0){
        this.tableData=this.tableData.filter(item=>idOffices.indexOf(item.officeId)!=-1);
      }
      if(idOffices.length!==this.lengthOfficeFilter){
        this.lengthOfficeFilter=idOffices.length;
        this.getClients(idOffices);
      }
      if(idOffices.length==0){
        this.lengthOfficeFilter=0;
        this.clientFilter=[];
        this.clients=[];
      }

      if(clients.length>0){
        this.tableData=this.tableData.filter(item=>clients.indexOf(item.corporate.id)!=-1);
      }

      if(idUsers.length>0){
        this.tableData=this.tableData.filter(item=>idUsers.indexOf(item.users.id)!=-1);
      }

      if(categories.length>0){
        this.tableData=this.tableData.filter(item=>categories.indexOf(item.category)!=-1);
      }

      if(status.length>0){
        this.tableData=this.tableData.filter(item=>status.indexOf(item.status)!=-1);
      }

      if(this.dateFilter[0]!=null && this.dateFilter[1]!=null){
        this.tableData=this.tableData.filter(item=>{
          var check = new Date(item.duedate);
          if(check > this.dateFilter[0] && check < this.dateFilter[1]){
            return true;
          }else{
            return false;
          }
        });
      }
      
    },
    load(event) {
      this.component.drawer = false;
      //
      if (event.update) {
        let indexUpdate=null;
        this.data.forEach(function(element, index, array){
          if(element.id==event.data.id){
            indexUpdate=index;
          }
        });

        this.data.splice(indexUpdate, 1, event.data);
        this.filterData();
      } else {
        this.data.unshift(event.data);
        this.filterData();
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(row, index) {
      this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            note
            .delete(row)
            .then(response => {
              let indexDelete=null;
              this.data.forEach(function(element, index, array){
                if(element.id==row.id){
                  indexDelete=index;
                }
              });
              this.data.splice(indexDelete, 1);
              this.tableData.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    },
    completeTask(row, index) {
      this.$swal({
          title: 'Are you sure?',
          text: "Task will be completed!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!'
        }).then((result) => {
          if (result.value) {
            note
            .completeTask(row)
            .then(response => {
              let indexUpdate = null;
              this.data.forEach(function(element, index, array){
                if(element.id==response.id){
                  indexUpdate=index;
                }
              });

              this.data.splice(indexUpdate, 1, response);
              this.filterData();
              this.$message({
                message: "Task Completed!",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .mx-datepicker{
    width:100% !important;
  }

  .mx-input{
    height: 42px !important;
  }

  .print-register{
    margin-left: 115px !important;
  }
</style>